<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Xuất excel</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="label-form">Năm</div>
                <v-autocomplete
                  v-model="form.year"
                  :items="yearList"
                  label="Năm"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="label-form">Tháng</div>
                <v-autocomplete
                  v-model="form.month"
                  :items="monthList"
                  item-text="name"
                  item-value="id"
                  placeholder="Tháng"
                  outlined
                  dense
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="dialog = false" depressed>
            Huỷ bỏ
          </v-btn>
          <v-btn color="primary" @click="exportExcel()">
            <v-icon left>mdi-microsoft-excel</v-icon>
            Xuất
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { monthList } from "@/constants/time";
import { generateArrayOfYears } from "@/utils/date.js";
import Cost from "@/api/cost";
import { saveAs } from "file-saver";
export default {
  data: () => ({
    dialog: false,
    monthList,
    yearList: generateArrayOfYears(10),
    form: {
      year: "",
      month: "",
    },
  }),
  methods: {
    async exportExcel() {
      const res = await Cost.costExport(this.form);
      saveAs(new Blob([res.data]), "Danhsachchiphi.xlsx");
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
  },
  watch: {},
};
</script>
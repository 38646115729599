import request from "../utils/request";
import { AApi } from "@/service/AApi";
const API = new AApi(
  { url: "costs" },
  {
    costExport: {
      async request(data) {
        return this.sdk({
          url: "/cost-export",
          responseType: "blob",
          params: data
        });
      },
    },
  }

)
export default API;
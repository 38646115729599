<template>
  <PageListLayout
    icon="mdi-folder-account"
    title="Danh sách chi phí"
    subTitle="Danh sách chi phí"
    useAdd
    @add="goCreate"
    @reset-filter="resetFilter"
    titleAdd="Tạo mới chi phí"
  >
    <template slot="side-right">
      <div class="d-flex align-center">
        <!--Tao moi-->
        <Create @on-done="getAll" ref="form" class="mr-5" />
        <Export ref="export" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-1" icon v-on="on" @click="showFormExport()">
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Kết xuất dữ liệu</span>
        </v-tooltip>
      </div>
    </template>

    <template slot="filter">
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.customers"
          label="khách hàng "
          :items="customersList"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.staffs"
          label="Nhân viên "
          :items="employers"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.costtype"
          label="Loại chi phí"
          :items="costtypeall"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleted(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
  </PageListLayout>
</template>
<script>
import { debounce } from "lodash";
import Create from "./create-edit";
import Export from "./export";
import Cost from "@/api/cost";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import { getAllCostType } from "@/api/costtype";
import KHACHHANG from "@/api/khachhang";
export default {
  components: { Create, Export },
  data() {
    return {
      formSearch: {
        customers: [],
        staffs: [],
      },
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      employers: [],
      customersList: [],
      costtypeall: [],
      headers: [
        { text: "Tên khách hàng", value: "customer.name" },
        { text: "Tên nhân viên", value: "staff.name" },
        { text: "Loại chi phí", value: "type.name" },
        { text: "Thời gian", value: "create_day" },
        { text: "Số tiền", value: "cost" },
        { text: "Ghi chú", value: "description" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  created() {
    this.loading = true;
    Promise.all([
      this.getAll(),
      this.getAllEmployee(),
      this.getAllCustomers(),
      this.getCostType(),
    ]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function (val) {
        this.page = 1;
        this.getAll();
      }, 300),
    },
  },
  methods: {
    goCreate() {
      this.$refs.form.showcreate();
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllCustomers() {
      let params ={
        'get_all': true,
        'deleted': true,
        'pagination':false
      };
      const res = await KHACHHANG.list(params);
      this.customersList = res.data;
    },
    async getCostType() {
      const res = await getAllCostType();
      this.costtypeall = res.data;
    },
    async getAll() {
      try {
        let cost = await Cost.list({
          page: this.page,
          perPage: this.itemsPerPage,
          ...this.formSearch,
        });
        this.tableData = cost.data.data;
        this.pageCount = cost.data.last_page;
      } catch (error) {
        console.log(error);
      }
    },
    changePage(val) {
      this.page = val;
      this.getAll();
    },
    async edit(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleted(item) {
      await Cost.destroy(item.id);
      this.getAll();
    },
    showFormExport() {
      this.$refs.export.show();
    },
  },
};
</script>
<style scoped>
.header__right__list_item {
  min-height: 40px !important;
  height: 40px !important;
}
.tab_color {
  color: #000;
}
.style--ellipsis {
  max-width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Thêm chi phí</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="label-form">Khách hàng</div>
                <v-autocomplete
                  v-model="form.customer_id"
                  :items="customersList"
                  item-text="name"
                  item-value="id"
                  placeholder="Khách hàng"
                  outlined
                  dense
                  :rules="required_rule"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="label-form">Loại chi phí</div>
                <v-autocomplete
                  :items="costtypeall"
                  label="Loại chi phí"
                  v-model="form.type"
                  item-text="name"
                  item-value="id"
                  required
                  outlined
                  dense
                  :rules="required_rule"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <div class="label-form">Nhân viên</div>
                <v-autocomplete
                  :items="employers"
                  v-model="form.staff_id"
                  label="Nhân viên"
                  item-text="name"
                  item-value="id"
                  required
                  outlined
                  dense
                  :rules="required_rule"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="label-form">Nhập số tiền</div>
                <v-text-field
                  label="Nhập số tiền"
                  v-model="form.cost"
                  :rules="numberRules"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  :hint="this.money"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <div class="label-form">Ngày xuất chi phí</div>
                <DateTimePicker
                  placeholder="Ngày xuất chi phí"
                  v-model="form.create_day"
                  :input-readonly="false"
                  format="DD/MM/YYYY"
                  :rules="required_rule"
                  hideDetails="auto"
                ></DateTimePicker>
              </v-col>
              <v-col cols="12">
                <div class="label-form">Ghi chú</div>
                <v-textarea
                  v-model="form.description"
                  placeholder="Ghi chú"
                  outlined
                  dense
                  prepend-inner-icon="mdi-note"
                  no-resize
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" depressed @click="dialog = false">
            Huỷ bỏ
          </v-btn>

          <v-btn color="primary" @click="createcost" v-if="!edit">
            <v-icon left>mdi-plus</v-icon>
            Thêm mới
          </v-btn>

          <v-btn color="primary" @click="editItem" v-else>
            <v-icon left>mdi-pencil</v-icon>
            Cập nhật
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import KHACHHANG from "@/api/khachhang";
import { getAllCostType } from "@/api/costtype";
import Cost from "@/api/cost";

export default {
  data: () => ({
    dialog: false,
    form: {
      staff_id: "",
      type: "",
      customer_id: "",
      cost: "",
      create_day: "",
      description: "",
    },
    money: "",
    employers: [],
    costtypeall: [],
    customersList: [],
    id: "",
    edit: false,
    required_rule: [(v) => !!v || "Không thể bỏ trống"],
    numberRules: [
      (v) => v.length > 0 || "Số tiền không được để trống",
      (v) => Number.isInteger(Number(v)) || "Số tiền phải là kiểu số",
      (v) => v > 0 || "Số tiền phải lớn hơn 0",
    ],
  }),
  mounted() {
    this.getAllEmployee();
    this.getAllType();
    this.getAllCustomers();
  },
  methods: {
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    showFormEdit(data) {
      this.id = data.id;
      this.dialog = true;
      this.form = { ...data, type: data?.type?.id };
      this.edit = true;
    },
    showcreate() {
      this.edit = false;
      this.dialog = true;
      this.form = {
        staff_id: "",
        type: "",
        customer_id: "",
        cost: "",
        create_day: "",
        description: "",
      };
    },
    async getAllCustomers() {
      let params = {
        get_all: true,
        deleted: true,
        pagination: false,
      };
      const res = await KHACHHANG.list(params);
      this.customersList = res.data;
    },
    async getAllType() {
      const response = await getAllCostType();
      this.costtypeall = response.data;
    },
    async createcost() {
      await Cost.create(this.form);
      this.dialog = false;
      this.form = {
        staff_id: "",
        type: "",
        customer_id: "",
        cost: "",
        create_day: "",
        description: "",
      };
      this.$emit("on-done");
    },
    async editItem() {
      await Cost.update(this.id, this.form);
      this.$emit("on-done");
      this.dialog = false;
    },
    //format money
    formatPrice(value) {
      let val = new Intl.NumberFormat().format(value);
      this.money = val;
    },
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.formatPrice(this.form.cost);
      },
    },
  },
};
</script>
